<template>
  <div>
    <page-header-main
        :sub-title="$t(header.subTitle)"
        :title="$t(header.title)"
        :description="$t(header.description)"
        :image-aspect-ratio="3540/1501"
        :image="header.image">
    </page-header-main>
    <section>
      <image-section tag="article" :section-title="idx === 0 ? $t(section1.title) : null"
                     :contents-sub-title="$t(content.subTitle)"
                     :contents-title="$t(content.title)"
                     contents-title-transform="capitalize"
                     :image="content.image"
                     :image-aspect-ratio="2040/1501"
                     :description="$t(content.description)"
                     v-for="(content, idx) in section1.contents" :key="idx"/>
    </section>


    <section>
      <image-section tag="article" :section-title="idx === 0 ? section2.title : null"
                     :contents-sub-title="$t(content.subTitle)"
                     :contents-title="$t(content.title)"
                     :image="content.image"
                     :image-aspect-ratio="3540/1501"
                     mode="row"
                     v-for="(content, idx) in section2.contents" :key="idx"/>
    </section>
  </div>
</template>

<script>
import PageHeaderMain from '@/layouts/ServiceIntro/Sections/PageHeaderMain';
import ImageSection from '@/layouts/ServiceIntro/Sections/ImageSection';

export default {
  name: 'TechnologyView',
  components: {
    PageHeaderMain,
    ImageSection,
  },
  data: () => ({
    header: {
      subTitle: 'Technology',
      title: 'techEngineShortDesc',
      description: 'techEngineDesc',
      image: require('@/assets/images/technology/main-visual@3x.png'),
    },
    section1: {
      title: 'Principle of Operation',
      contents: [
        {
          subTitle: 'STEP 1',
          title: 'techEngineStep1',
          description: 'techEngineStepDesc1',
          image: require('@/assets/images/technology/step01-train interaction@3x.png'),
        },
        {
          subTitle: 'STEP 2',
          title: 'techEngineStep2',
          description: 'techEngineStepDesc2',
          image: require('@/assets/images/technology/step02-predict futuristic@3x.png'),
        },
        {
          subTitle: 'STEP 3',
          title: 'techEngineStep3',
          description: 'techEngineStepDesc3',
          image: require('@/assets/images/technology/step03-predict item@3x.png'),
        },
        {
          subTitle: 'STEP 4',
          title: 'techEngineStep4',
          description: 'techEngineStepDesc4',
          image: require('@/assets/images/technology/step04-predict future consumer@3x.png'),
        },
      ],
    },

    section2: {
      title: 'Features',
      contents: [
        {
          subTitle: 'Feature 1',
          title: 'techFeatureShortDesc1',
          image: require('@/assets/images/technology/feature01-realtime preference reflextion.gif'),
        },
        {
          subTitle: 'Feature 2',
          title: 'techFeatureShortDesc2',
          image: require(
              '@/assets/images/technology/feature02-excellent prediction performance@3x.png'),
        },
        {
          subTitle: 'Feature 3',
          title: 'techFeatureShortDesc3',
          image: require('@/assets/images/technology/feature03-fast and low price@3x.png'),
        },
      ],
    },
  }),
};
</script>
