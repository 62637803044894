<template>
  <section-wrap :tag="tag">
    <div class="section-title text-t2 text-center font-weight-bold"
         :class="`text-${sectionTitleTransform}`"
         data-aos="fade-down"
         v-if="sectionTitle">
      <slot name="section-title">{{ sectionTitle }}</slot>
    </div>

    <v-row class="contents-area" justify="space-between" :align="alignItem ? alignItem : 'center'">
      <v-col class="pt-0" cols="12" :md="mode === 'row' ? 12 : 5" :lg="mode === 'row' ? 12 : 4"
             :order="isReverse ? 2 : 1">
        <div class="text-t4 font-weight-bold pb-4" :class="`text-${contentsSubTitleTransform}`"
             :data-aos="animationFirst" v-if="contentsSubTitle">
          {{ contentsSubTitle }}
        </div>
        <div class="text-t3 font-weight-bold pb-5" :class="`text-${contentsTitleTransform}`"
             :data-aos="animationFirst" v-if="contentsTitle">
          {{ contentsTitle }}
        </div>
        <div class="text-t4 paragraph--text pb-5" :data-aos="animationFirst" v-if="description">
          {{ description }}
        </div>
      </v-col>
      <v-col class="py-0" cols="12" :md="mode === 'row' ? 12 : 7"
             :order="isReverse ? 1 : 2">
        <slot name="image">
          <v-img :src="image" :lazy-src="image"
                 :alt="computedImageAlt"
                 :data-aos="animationSecond"
                 width="100%" :aspect-ratio="computedAspectRatio" contain>
            <template #placeholder>
              <image-place-holder/>
            </template>
          </v-img>
        </slot>
      </v-col>
    </v-row>
  </section-wrap>
</template>

<script>
import SectionWrap from './SectionWrap';
import ImagePlaceHolder from '@/components/ImagePlaceHolder';

export default {
  name: 'ImageSection',
  components: {
    SectionWrap,
    ImagePlaceHolder,
  },
  props: {
    sectionTitle: {
      type: String,
      default: null,
    },
    sectionTitleTransform: {
      type: String,
      default: 'inherit',
      validator: (val) => {
        return ['inherit', 'capitalize', 'uppercase', 'lowercase'].indexOf(val) !== -1;
      },
    },
    contentsTitle: {
      type: String,
      default: null,
    },
    contentsTitleTransform: {
      type: String,
      default: 'inherit',
      validator: (val) => {
        return ['inherit', 'capitalize', 'uppercase', 'lowercase'].indexOf(val) !== -1;
      },
    },
    contentsSubTitle: {
      type: String,
      default: '',
    },
    contentsSubTitleTransform: {
      type: String,
      default: 'inherit',
      validator: (val) => {
        return ['inherit', 'capitalize', 'uppercase', 'lowercase'].indexOf(val) !== -1;
      },
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      default: null,
    },
    imageAlt: {
      type: String,
      default: null,
    },
    imageAspectRatio: {
      type: [String, Number],
      default: 0,
    },

    mode: {
      type: String,
      default: 'col',
      validator: (val) => {
        return ['row', 'col'].indexOf(val) !== -1;
      },
    },
    alignItem: {
      type: String,
      default: null,
      validator: (val) => {
        return ['start', 'center', 'end'].indexOf(val) !== -1;
      },
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'section',
    },
  },
  computed: {
    computedImageAlt() {
      return this.imageAlt ? this.imageAlt : this.title;
    },
    computedAspectRatio() {
      const defaultRatio = this.mode === 'row' ? (1180 / 500) : (680 / 500);
      return this.imageAspectRatio ? this.imageAspectRatio : defaultRatio;
    },
    isReverse() {
      const isBreakPoint = this.$vuetify.breakpoint.smAndDown;

      return isBreakPoint && this.mode === 'col' ? false : this.reverse;
    },
    animationFirst() {
      const isBreakPoint = this.$vuetify.breakpoint.smAndDown;
      const direction = this.reverse ? 'right' : 'left';

      return isBreakPoint || this.mode === 'row' ? 'fade-down' : `fade-${direction}`;
    },
    animationSecond() {
      const isBreakPoint = this.$vuetify.breakpoint.smAndDown;
      const direction = this.reverse ? 'left' : 'right';

      return isBreakPoint || this.mode === 'row' ? 'fade-up' : `fade-${direction}`;
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 60px;
}
</style>
